<template>
  <div class="qr-container">
    <qrcode-stream
      :camera="camera"
      :constraints="selectedConstraint"
      @decode="onDecode"
      @init="onInit"
    />
    <Transition name="simple-fade">
      <div
        v-if="streamRendered && hasManualInput"
        class="qr-manual-input background-white d-flex align-center"
      >
        <!--        <v-btn-->
        <!--          icon-->
        <!--          :disabled="videoDevices.length <= 1"-->
        <!--          class="ml-2"-->
        <!--          @click="switchCamera"-->
        <!--        >-->
        <!--          <v-icon>mdi-camera-flip</v-icon>-->
        <!--        </v-btn>-->
        <v-text-field
          v-model="manualInput"
          class="mx-2"
          clearable
          dense
          flat
          hide-details
          placeholder="Or type manually here..."
          solo
        />
        <v-btn
          :disabled="manualInput.length === 0"
          color="primary"
          elevation="0"
          height="38px"
          tile
          @click="manualDecode"
          >scan
        </v-btn>
      </div>
    </Transition>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'BaseAntScanner',
  components: {
    QrcodeStream,
  },
  props: {
    decodeParser: {
      type: Function,
      default: (value) => value,
    },
    hasManualInput: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    camera: 'auto',
    manualInput: '',
    streamRendered: false,
    videoDevices: [],
    videoDeviceIndex: 0, // Start with the first device
  }),
  computed: {
    selectedConstraint() {
      const device = this.videoDevices[this.videoDeviceIndex];
      return device
        ? { video: { deviceId: { exact: device.deviceId } } }
        : { video: true };
    },
  },
  methods: {
    switchCamera() {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear';
          break;
        case 'rear':
          this.camera = 'front';
          break;
      }
    },
    manualDecode() {
      this.$emit('decodedResult', this.manualInput);
      this.manualInput = '';
      this.camera = 'off';
      this.$nextTick(() => {
        this.camera = 'rear';
      });
    },
    onDecode(result) {
      this.$emit('decodedResult', this.decodeParser(result));

      this.manualInput = '';
      this.camera = 'off';
      this.$nextTick(() => {
        this.camera = 'rear';
      });
    },
    async onInit(promise) {
      try {
        await promise;
        this.streamRendered = true;

        navigator.mediaDevices.enumerateDevices().then((devices) => {
          this.videoDevices = devices
            .filter((device) => device.kind === 'videoinput')
            .map(({ deviceId, label }) => ({
              label: `${label} (ID: ${deviceId})`,
              deviceId: deviceId, // Store deviceId directly
            }));
        });
      } catch (error) {
        // Error handling remains the same
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-container {
  position: relative;

  .qr-manual-input {
    position: absolute;
    bottom: 25px;
    width: 80%;
    left: 10%;
    border-radius: 20px;
    overflow: hidden;
  }
}
</style>
